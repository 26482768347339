// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 5%;
}

.party-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 50%;
}

.party-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#join-party {
  border-radius: 24px;
  width: 35%;
}

#create-party {
  border-radius: 24px;
  width: 35%;
}

.role {
  min-width: 80vw;
  padding: 20px;
  margin: 20px;
  border-radius: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,WAAW;EACX,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  height: 100vh;\n  padding: 0 5%;\n}\n\n.party-container {\n  display: flex;\n  justify-content: space-around;\n  width: 100%;\n  height: 50%;\n}\n\n.party-container > div {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n#join-party {\n  border-radius: 24px;\n  width: 35%;\n}\n\n#create-party {\n  border-radius: 24px;\n  width: 35%;\n}\n\n.role {\n  min-width: 80vw;\n  padding: 20px;\n  margin: 20px;\n  border-radius: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
